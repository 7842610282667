<!--
 * @Description: 组织举报列表页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors  : 蒙川
 * @LastEditTime : 2020-01-13 14:38:58
 -->
<template>
  <div class="orgTipOffList-wrapper">
    <list ref="list"
          exportPermission="export"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers">
      <template #searchSlot>
        <v-select2 label="被举报组织"
                   placeholder="查询被举报组织"
                   v-model="searchParams.orgId"
                   v-bind="orgParams"></v-select2>
        <v-select2 label="住址信息"
                   placeholder="查询住址信息"
                   v-model="searchParams.houseId"
                   v-bind="userRoomParams"></v-select2>
        <v-select2 label="组织超管"
                   placeholder="查询组织超管"
                   v-model="searchParams.orgManagerId"
                   v-bind="orgAdminParams"></v-select2>
        <v-input label="举报人"
                 v-model="searchParams.reportUserName"></v-input>
        <v-select label="审核状态"
                  v-model="searchParams.approveStatus"
                  :options="statusOps"></v-select>
        <v-datepicker label="举报时间"
                      type="rangedatetimer"
                      :startTime.sync="searchParams.startTime"
                      :endTime.sync="searchParams.endTime" />
        <v-select2 label="所属项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司"
                   v-model="searchParams.regionId"
                   v-bind="tenantParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button :text="scope.row.approveStatus === 0 ? '编辑' : '查看'"
                  type="text"
                  permission="update"
                  @click="rightButtonAction(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  getOrgListURL,
  getUserRoomsListURL,
  getOrgAdminListURL,
} from './api'
import { statusOps, statusMap } from './map'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: '',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: statusOps(1),
      communityParams,
      tenantParams,
      orgParams: {
        searchUrl: getOrgListURL,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName'
        }
      },
      userRoomParams: {
        searchUrl: getUserRoomsListURL,
        method: 'post',
        request: {
          text: 'roomNum',
          value: 'houseId'
        },
        response: {
          text: 'name'
        }
      },
      orgAdminParams: {
        searchUrl: getOrgAdminListURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username'
        }
      },
      searchParams: {
        orgId: '',
        orgManagerId: '',
        houseId: '',
        startTime: '',
        endTime: '',
        reportUserName: '',
        communityId: '',
        regionId: '',
        approveStatus: undefined,
        status: undefined
      },
      /// 列表页面的headers
      headers: [
        {
          prop: 'orgName',
          label: '被举报组织'
        },
        {
          prop: 'address',
          label: '地址信息'
        },
        {
          prop: 'managerName',
          label: '组织超管'
        },
        {
          prop: 'managerMobile',
          label: '联系电话'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'reportUserName',
          label: '举报者'
        },
        {
          prop: 'reportReason',
          label: '举报原因'
        },
        {
          prop: 'reportTime',
          label: '举报时间'
        },
        {
          prop: 'statusText',
          label: '审核状态',
          formatter (row) {
            let statusText = statusMap[row.approveStatus]
            return statusText
          }
        },
        {
          prop: 'approveComments',
          label: '处理原因'
        }
      ]
    }
  },

  methods: {

    // 右侧按钮点击事件
    rightButtonAction (item) {
      // 传值要写成字符串，否则this.$route.query.xxx 重新加载会变成字符串类型
      this.$router.push({
        name: 'orgTipOffDetail',
        query: {
          edit: `${item.approveStatus}`,
          id: item.id
        }
      })
    }
  },
  mounted () {
    const { status } = this.$route.query
    if (status !== undefined) {
      this.searchParams = {
        orgId: '',
        orgManagerId: '',
        houseId: '',
        startTime: '',
        endTime: '',
        reportUserName: '',
        communityId: '',
        regionId: '',
        approveStatus: undefined,
        status: undefined
      }
      this.searchParams.approveStatus = status
    }
  },
}
</script>
