// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}orgComplaint/pageList`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}orgComplaint/list/export`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 获取住址信息列表
const getUserRoomsListURL = `${API_CONFIG.baseURL}serverCodewordAction!getUserRooms.action`
// 获取组织超管列表
const getOrgAdminListURL = `${API_CONFIG.butlerBaseURL}user/search/keyword`
// 获取举报详情
const getOrgComplaintInfoURL = `${API_CONFIG.butlerBaseURL}orgComplaint/getOrgComplaintInfo`
// 举报处理操作
const orgComplaintDealURL = `${API_CONFIG.butlerBaseURL}orgComplaint/orgComplaintDeal`
// 获取房号列表
const getHouseListURL = `${API_CONFIG.baseURL}serverCodewordAction!getUserRooms.action`

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  getUserRoomsListURL,
  getOrgAdminListURL,
  getOrgComplaintInfoURL,
  orgComplaintDealURL,
  getHouseListURL
}
