import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '待处理',
    value: 0
  },
  {
    text: '忽略',
    value: 1
  },
  {
    text: '移除',
    value: 2
  },
  {
    text: '修正',
    value: 3
  },
  {
    text: '已主动搬离',
    value: 4
  }
]

const {
  map: statusMap,
  setOps: statusOps
} = mapHelper.setMap(status)

// 性别
const sexType = [
  {
    text: '未知',
    value: 0
  },
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]

const {
  map: sexTypeMap,
  setOps: sexTypeOps
} = mapHelper.setMap(sexType)

export {
  statusOps,
  statusMap,
  sexTypeMap,
  sexTypeOps
}
