var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orgTipOffList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: {
                        label: "被举报组织",
                        placeholder: "查询被举报组织",
                      },
                      model: {
                        value: _vm.searchParams.orgId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgId", $$v)
                        },
                        expression: "searchParams.orgId",
                      },
                    },
                    "v-select2",
                    _vm.orgParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "住址信息", placeholder: "查询住址信息" },
                      model: {
                        value: _vm.searchParams.houseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "houseId", $$v)
                        },
                        expression: "searchParams.houseId",
                      },
                    },
                    "v-select2",
                    _vm.userRoomParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "组织超管", placeholder: "查询组织超管" },
                      model: {
                        value: _vm.searchParams.orgManagerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgManagerId", $$v)
                        },
                        expression: "searchParams.orgManagerId",
                      },
                    },
                    "v-select2",
                    _vm.orgAdminParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "举报人" },
                  model: {
                    value: _vm.searchParams.reportUserName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "reportUserName", $$v)
                    },
                    expression: "searchParams.reportUserName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "审核状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.approveStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "approveStatus", $$v)
                    },
                    expression: "searchParams.approveStatus",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "举报时间",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.tenantParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    text: scope.row.approveStatus === 0 ? "编辑" : "查看",
                    type: "text",
                    permission: "update",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.rightButtonAction(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }